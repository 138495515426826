<template>
	<div>
		<pui-datatable :modelName="modelName" :showDeleteBtn="false" :modelColumnDefs="modelColumnDefs" :showCreateBtn="false"></pui-datatable>
	</div>
</template>

<script>
export default {
	name: 'mports-grid',
	components: {},
	data() {
		return {
			modelName: 'mports',

			modelColumnDefs: {}
		};
	},
	methods: {}
};
</script>
